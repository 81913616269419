<template>
  <div class="demand-view" :class="{ 'has-chart': isChartView }">
    <div v-if="!isChartView" class="portf-demand-view">
      <div class="portf-row portf-bold portf-table-header portf-uppercase">
        <div class="part1">
          <!-- Consumer Robots -->
        </div>
        <div class="part2">
          <div class="data-child mr-1 portf-uppercase pr-1" v-for="(ft, fi) in c_fields" :key="fi"
            :style="`width:${100 / c_fields.length}%`">
            {{ ft }}
          </div>
        </div>
      </div>
      <div v-if="otype !== 'program'">
        <div v-for="(item, index) in this.c_data" :key="index">
          <div class="portf-row portf-bold portf-sub-header portf-table-row color-white row-header-bg border-btm-lgt"
            :class="{ 'inner-sdw': index === 0 }">
            <div class="part1 portf-uppercase" style="cursor:pointer" v-on:click="onCollapseCLick(index)">
              <feather-icon :icon="opened === index ? 'ChevronDownIcon' : 'ChevronRightIcon'" v-if="item.children"
                size="16" class="mr-1" />
              {{ item.title }}
            </div>
            <div class="part2">
              <div class="data-child mr-1 pr-1" v-for="(ft, fi) in c_fields" :key="fi"
                :style="`width:${100 / c_fields.length}%`">
                <span v-if="ft === 'priority'">{{ item[ft] }}</span>
                <span v-else-if="ft === 'deadline'">{{ dateFormat(item[ft]) }}</span>
                <span v-else>{{ formatCurrency(item[ft]) }}</span>
              </div>
            </div>
            <div class="part3 d-flex justify-content-center">
              <b-button @click="toggleEditDrawerOpen" variant="flat-primary">
                <feather-icon icon="Edit2Icon" />
              </b-button>
              <b-button @click="toggleDrawerOpen" variant="flat-primary">
                <feather-icon icon="DollarSignIcon" />
              </b-button>
              <!-- <b-button variant="flat-primary">
                <feather-icon icon="ChevronsRightIcon" />
              </b-button> -->
            </div>
          </div>
          <div v-if="opened === index">
            <div v-for="(item1, index1) in item.children" :key="index1">
              <div class="portf-row portf-table-row font-14 border-bottom-dm" :class="{ 'inner-sdw': index1 === 0 }">
                <div class="part1 portf-bold pl-2" style="padding-top:7px">
                  {{ item1.title }}
                </div>
                <div class="part2">
                  <div class="data-child mr-1" v-for="(ft, fi) in c_fields" :key="fi"
                    :style="`width:${100 / c_fields.length}%`">
                    <div v-if="demandTableEditable">
                      <v-select v-if="ft === 'priority'" v-model="item1[ft]"
                        :options="['Highest', 'High', 'Low', 'Lowest']" outlined />
                      <b-form-input v-else-if="ft === 'deadline'" v-model="item1[ft]" style="text-align:end" />
                      <b-input-group v-else-if="ft === 'authorised' || ft === 'spent' || ft === 'demand'">
                        <b-form-input type="number" style="text-align:end" v-model="item1[ft]" />
                        <b-input-group-append>
                          <b-input-group-text class="bg-transparent font-weight-bold">
                            €
                          </b-input-group-text>
                        </b-input-group-append>
                      </b-input-group>
                      <div v-else class="mr-1" style="margin-top:6px;">
                        {{ formatCurrency(item1[ft]) }}
                      </div>
                    </div>
                    <div v-else>
                      <div v-if="ft === 'priority'" class="mr-1" style="margin-top:6px;">
                        {{ item1[ft] }}
                      </div>
                      <div v-else-if="ft === 'deadline'" class="mr-1" style="margin-top:6px;">
                        {{ dateFormat(item1[ft]) }}
                      </div>
                      <div v-else class="mr-1" style="margin-top:6px;">
                        {{ formatCurrency(item1[ft]) }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="part3 d-flex justify-content-center">
                  <b-button variant="flat-primary">
                    <feather-icon icon="Edit2Icon" />
                  </b-button>
                  <b-button variant="flat-primary">
                    <feather-icon icon="DollarSignIcon" />
                  </b-button>
                  <!-- <b-button variant="flat-primary">
                    <feather-icon icon="ChevronsRightIcon" />
                  </b-button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="portf-row portf-bold portf-sub-header portf-table-row color-white row-header-bg border-btm-lgt"
          :class="{ 'inner-sdw': index === 0 }">
          <div class="part1 portf-uppercase">
            {{ data.title }}
          </div>
          <div class="part2">
            <div class="data-child mr-1 pr-1" v-for="(ft, fi) in c_fields" :key="fi"
              :style="`width:${100 / c_fields.length}%`">
              <span v-if="ft === 'priority'">{{ data[ft] }}</span>
              <span v-else-if="ft === 'deadline'">{{ dateFormat(data[ft]) }}</span>
              <span v-else>{{ formatCurrency(data[ft]) }}</span>
            </div>
          </div>
        </div>
        <div v-for="(item, index) in this.c_data" :key="index">
          <div class="portf-row portf-table-row font-14 border-bottom-dm" :class="{ 'inner-sdw': index === 0 }">
            <div class="part1 portf-bold pl-2" style="padding-top:7px">
              {{ item.title }}
            </div>
            <div class="part2">
              <div class="data-child mr-1" v-for="(ft, fi) in c_fields" :key="fi"
                :style="`width:${100 / c_fields.length}%`">
                <div v-if="demandTableEditable">
                  <v-select v-if="ft === 'priority'" v-model="item[ft]" :options="['Highest', 'High', 'Low', 'Lowest']"
                    outlined />
                  <b-form-input v-else-if="ft === 'deadline'" v-model="item[ft]" style="text-align:end" />
                  <b-input-group v-else-if="ft === 'authorised' || ft === 'spent' || ft === 'demand'">
                    <b-form-input type="number" style="text-align:end" v-model="item[ft]" />
                    <b-input-group-append>
                      <b-input-group-text class="bg-transparent font-weight-bold">
                        €
                      </b-input-group-text>
                    </b-input-group-append>
                  </b-input-group>
                  <div v-else class="mr-1" style="margin-top:6px;">
                    {{ formatCurrency(item[ft]) }}
                  </div>
                </div>
                <div v-else>
                  <div v-if="ft === 'priority'" class="mr-1" style="margin-top:6px;">
                    {{ item[ft] }}
                  </div>
                  <div v-else-if="ft === 'deadline'" class="mr-1" style="margin-top:6px;">
                    {{ dateFormat(item[ft]) }}
                  </div>
                  <div v-else class="mr-1" style="margin-top:6px;">
                    {{ formatCurrency(item[ft]) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="portf-row portf-bold portf-table-header portf-uppercase" style="font-size:18px">
        <div class="part1 portf-uppercase">
          Total
        </div>
        <div class="part2">
          <div class="data-child mr-1 portf-uppercase pr-1" v-for="(ft, fi) in c_fields" :key="fi"
            :style="`width:${100 / c_fields.length}%`">
            <div v-if="ft === 'budget'">
              {{ formatCurrency(c_totalBudget) }}
            </div>
            <div v-else-if="ft === 'engaged'">
              {{ formatCurrency(c_totalEngaged) }}
            </div>
            <div v-else-if="ft === 'quote'">
              {{ formatCurrency(c_totalQuote) }}
            </div>
            <div v-else-if="ft === 'demand'">
              {{ formatCurrency(c_totalDemand) }}
            </div>
            <div v-else-if="ft === 'realEstimated'">
              {{ formatCurrency(c_totalReal) }}
            </div>
            <div v-else-if="ft === 'authorised'">
              {{ formatCurrency(c_totalAuthor) }}
            </div>
            <div v-else-if="ft === 'spent'">
              {{ formatCurrency(c_totalSpent) }}
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div v-if="isChartView" class="d-flex flex-column w-100" style>
      <b-card v-for="(serie, idx) in c_series" :key="idx" no-body no-footer class="chart-card">
        <b-row>
          <b-col>
            <h2>{{ serie[0].title }}</h2>
            <div class="d-flex justify-content-between align-center mt-1">
              <p class="text-uppercase m-0">
                Budget Portfolio
              </p>
              <p class="m-0">
                {{ formatCurrency(getTotalValue(serie)) }}
              </p>
            </div>
            <vue-apex-charts type="bar" height="248" :options="chartOptions" :series="serie" />
          </b-col>
          <b-col>
            <b-row cols="2">
              <b-col v-for="(color, index) in chartOptions.colors" :key="index" class="mb-1">
                <div class="d-flex justify-content-between align-center mb-1">
                  <p class="text-capitalize m-0">
                    {{ chartOptions.xaxis.categories[index] }}
                  </p>
                  <p class="m-0">
                    {{ getPercent(serie[0].data[index], getTotalValue(serie)) }}%
                  </p>
                </div>
                <b-progress :max="getTotalValue(serie)">
                  <b-progress-bar :value="serie[0].data[index]" :style="{ 'background-color': color }" />
                </b-progress>
                <p class="mt-1">
                  {{ formatCurrency(serie[0].data[index]) }}
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <Drawer @close="toggleDrawerOpen" align="right" :closeable="false" :maskClosable="true" >
      <div v-if="budgetDrawerOpen" >
        <BudgetDrawer />
      </div>
    </Drawer>
    <Drawer @close="toggleEditDrawerOpen" align="right" :closeable="false" :maskClosable="true" >
      <div v-if="editDrawerOpen" >
        <EditDrawer />
      </div>
    </Drawer>
  </div>
</template>

<script>
import {
  BButton, BCard, BFormInput, BRow, BCol, BProgress, BProgressBar, BInputGroup, BInputGroupAppend, BInputGroupText
} from 'bootstrap-vue'
import moment from 'moment'
import Drawer from "vue-simple-drawer"
import VueApexCharts from 'vue-apexcharts'
import vSelect from 'vue-select'
import BudgetDrawer from '../modals/BudgetDrawer.vue'
import EditDrawer from '../modals/EditDrawer.vue'

export default {
  components: {
    BButton,
    BCard,
    BFormInput,
    BRow,
    BCol,
    BProgress,
    BProgressBar,
    VueApexCharts,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,
    vSelect,
    Drawer,
    BudgetDrawer,
    EditDrawer
  },
  props: {
    data: {
      type: Object,
      default: () => { },
    },
    fields: {
      type: Array,
      default: () => [],
    },
    isChartView: {
      type: Boolean,
      default: false,
    },
    otype: {
      type: String
    }
  },
  data() {
    return {
      budgetDrawerOpen: false,
      editDrawerOpen: false,
      opened: 0,
      chartOptions: {
        chart: {
          type: 'bar',
          foreColor: 'rgba(255, 255, 255, 0.8)',
          toolbar: {
            show: false,
          },
        },
        grid: {
          borderColor: '#595E71',
          padding: {
            left: 50,
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        colors: ['#7367F0', '#D46D6D', '#BC00F9', '#00CFE8', '#0D6EFD', '#28C76F'],
        dataLabels: {
          enabled: true,
          formatter: val => `€${val}`
        },
        legend: {
          show: true,
        },
        tooltip: {
          enabled: true,
        },
        plotOptions: {
          bar: {
            distributed: true, // this line is mandatory
            horizontal: true,
          },
        },
        xaxis: {
          categories: ['budget', 'demand', 'quote + engaged', 'real estimated', 'authorised', 'spent'],
          labels: {
            formatter: val => `€${val}`
          }
        },
        yaxis: {
          labels: {
            show: true,
            align: 'left',
            offsetX: '0',
            style: {
              cssClass: 'text-uppercase',
            },
          },
        },
      },
    }
  },
  computed: {
    c_fields() {
      return this.fields.slice(1, this.fields.length - 1)
    },
    demandTableEditable() {
      return this.$store.state.portfolioState.demandTableEditable
    },
    c_data() {
      if (this.data.children) {
        const ndt = this.data.children.map(t => {
          let budget = 0
          let engaged = 0
          let quote = 0
          let demand = 0
          let realEstimated = 0
          let authorised = 0
          let spent = 0
          if (t.children) {
            t.children.map(t1 => {
              budget += parseInt(t1.budget ? t1.budget : 0, 10)
              engaged += parseInt(t1.engaged ? t1.engaged : 0, 10)
              quote += parseInt(t1.quote ? t1.quote : 0, 10)
              demand += parseInt(t1.demand ? t1.demand : 0, 10)
              realEstimated += parseInt(t1.realEstimated ? t1.realEstimated : 0, 10)
              authorised += parseInt(t1.authorised ? t1.authorised : 0, 10)
              spent += parseInt(t1.spent ? t1.spent : 0, 10)
              return null
            })
          } else {
            budget = t.budget
            engaged = t.engaged
            quote = t.quote
            demand = t.demand
            realEstimated = t.realEstimated
            authorised = t.authorised
            spent = t.spent
          }
          const nd = { ...t }
          nd.budget = budget
          nd.engaged = engaged
          nd.quote = quote
          nd.demand = demand
          nd.realEstimated = realEstimated
          nd.authorised = authorised
          nd.spent = spent
          return nd
        })
        return ndt
      }
      return []
    },
    c_totalBudget() {
      let bd = 0
      this.c_data.forEach(t => {
        bd += t.budget ? parseInt(t.budget, 10) : 0
      })
      return bd
    },
    c_totalEngaged() {
      let bd = 0
      this.c_data.forEach(t => {
        bd += t.engaged ? parseInt(t.engaged, 10) : 0
      })
      return bd
    },
    c_totalQuote() {
      let bd = 0
      this.c_data.forEach(t => {
        bd += t.quote ? parseInt(t.quote, 10) : 0
      })
      return bd
    },
    c_totalDemand() {
      let bd = 0
      this.c_data.forEach(t => {
        bd += t.demand ? parseInt(t.demand, 10) : 0
      })
      return bd
    },
    c_totalReal() {
      let bd = 0
      this.c_data.forEach(t => {
        bd += t.realEstimated ? parseInt(t.realEstimated, 10) : 0
      })
      return bd
    },
    c_totalSpent() {
      let bd = 0
      this.c_data.forEach(t => {
        bd += t.spent ? parseInt(t.spent, 10) : 0
      })
      return bd
    },
    c_totalAuthor() {
      let bd = 0
      this.c_data.forEach(t => {
        bd += t.authorised ? parseInt(t.authorised, 10) : 0
      })
      return bd
    },
    c_series() {
      return [
        [{
          title: 'Consumer Robots',
          data: [this.c_totalBudget, this.c_totalDemand, this.c_totalQuote + this.c_totalEngaged, this.c_totalReal, this.c_totalAuthor, this.c_totalSpent],
        }]
      ]
    }
  },
  methods: {
    toggleDrawerOpen() {
      this.budgetDrawerOpen = !this.budgetDrawerOpen
    },
    toggleEditDrawerOpen() {
      this.editDrawerOpen = !this.editDrawerOpen
    },
    onCollapseCLick(index) {
      if (index === this.opened) {
        this.opened = -1
      } else {
        this.opened = index
      }
    },
    dateFormat(date) {
      if (date) return moment(new Date(date)).format('MM-DD-YYYY')
      return null
    },
    formatCurrency(value) {
      return new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: 'EUR',
      }).format(value)
    },
    rowClass(item, type) {
      const colorClass = 'table-success'
      if (!item || type !== 'row') { return }

      // eslint-disable-next-line consistent-return
      if (item.title === 'total') { return colorClass }
    },
    getTotalValue(data) {
      let totalValue = 0
      data[0].data.forEach(val => {
        totalValue += val
      })
      return totalValue
    },
    getPercent(val, total) {
      return Math.round((val / total) * 100)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-portfolio.scss';
@import '@core/scss/vue/pages/dashboard-portfolio-demand.scss';
</style>
